@import '../../scss/variables';

.uploader-loader {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    color: $blue-50;
  }

  &__progress {
    margin-top: 9px;
    width: 80px;
    height: 8px;
    border-radius: 13px;
    background-color: $blue-10;

    &__bar {
      background-color: $dark-blue;
    }
  }
}
