@import '../scss/variables';
@import '~@coreui/coreui-pro/scss/bootstrap/mixins/breakpoints';

.anonymous-docs {
  display: flex;
  flex-direction: column;
  max-width: 202px;

  &__title {
    margin-bottom: 12px;
    color: $blue-80;
    @include media-breakpoint-down(xs) {
      position: absolute;
      left: 115px;
      top: 20px;
      width: 100vw;
    }
  }

  &__docs-display {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 202px;
    height: 162px;
    border-radius: 6px;
    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
    }

    @include media-breakpoint-down(xs) {
      max-width: 100px;
      height: 90px;
      width: auto;
    }

    &--success {
      background-color: $blue-25;
    }

    &--failed {
      background-color: $red-25;
    }

    &--pending {
      background-color: $yellow-25;
    }
  }

  &__verified-status {
    @include media-breakpoint-down(xs) {
      position: absolute;
      top: 50px;
      left: 115px;
      width: 100vw;
    }

    i {
      margin-right: 8px;
    }

    &--success, &--success i {
      color: $green-10;
    }

    &--failed, &--failed i {
      color: $rose-red;
    }

    &--pending, &--pending i {
      color: $yellow-40;
    }
  }
}
