@import '../scss/variables';
@import '~@coreui/coreui-pro/scss/bootstrap/mixins/breakpoints';

.uploader {
  display: inline-flex;
  flex-direction: column;
  max-width: 202px;

  * {
    outline: none;
  }

  &__bg {
    background-color: #f9f9f9;
  }

  &__remove-btn {
    &--hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__title {
    color: $blue-80;
    white-space: pre-wrap;
    @include media-breakpoint-down(xs) {
      max-width: 100px;
      width: auto;
    }
  }

  &__error {
    padding-top: 5px;
    color: $error;
    white-space: pre-wrap;
  }


  &__drag-box {
    width: 202px;
    height: 162px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: dashed 1px $gray-30;
    transition: border-color 0.3s, background-color 0.5s;
    cursor: pointer;

    @include media-breakpoint-down(xs) {
      max-width: 100px;
      max-height: 90px;
    }

    &--disabled {
      cursor: progress;
    }

    &--success {
      border-color: transparent;
      background-color: $gray-10;
    }

    &--error {
      border: 1px dashed $error;
    }

    &--success &--active, &--active {
      border: 1px solid $green-10;
    }

    &__add-document {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;

      &__uploaded-image {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        border-radius: 4px;
        align-self: normal;
        margin: auto;
      }

      &__supported {
        padding-top: 15px;
        color: $gray-30;
      }

      &__browse {
        padding-top: 8px;
        color: $dark-blue;
      }

      @include media-breakpoint-down(md) {
        &__browse, &__supported {
          padding-top: 5px;
        }
      }
      @include media-breakpoint-down(xs) {
        &__browse, &__supported {
          display: none;
        }
        img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
        }
      }
    }
  }
}
